export const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    customRender: (value, item, index) => {
      return index + 1
    },
  },
  {
    title: '名称',
    dataIndex: 'material_name',
    key: 'material_name',
  },
  {
    title: '产品编号',
    dataIndex: 'material_number',
    key: 'material_number',
  },
  {
    title: '批次',
    key: 'batch',
    scopedSlots: { customRender: 'batch' },
    width: 240,
  },
  {
    title: '数量',
    key: 'total_quantity',
    scopedSlots: { customRender: 'total_quantity' },
  },
  {
    title: '单位',
    dataIndex: 'unit',
    key: 'unit',
  },
  {
    title: '总重量(吨)',
    dataIndex: 'weight',
    key: 'weight',
    scopedSlots: { customRender: 'weight' },
  },
  {
    title: '总体积(立方)',
    dataIndex: 'volume',
    key: 'volume',
    scopedSlots: { customRender: 'volume' },
  },
  {
    title: '包装规格',
    dataIndex: 'material_spec',
    key: 'material_spec',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]